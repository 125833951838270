export enum Products {
  BRONZE = 'BRONZE',
  GOLD = 'GOLD',
  ENTERPRISE = 'ENTERPRISE',
  NONE = 'NONE'
}

export enum Type {
  COMPANY = 'COMPANY',
  INSPECTOR = 'INSPECTOR',
  OTHER = 'OTHER'
}

export enum Industry {
  TESTING = 'TESTING',
  COMPLIANCE = 'COMPLIANCE',
  AUDIT = 'AUDIT',
  CERTIFICATION = 'CERTIFICATION',
  INSPECTIONS = 'INSPECTIONS',
  CONSTRUCTION = 'CONSTRUCTION',
  MANUFACTURING = 'MANUFACTURING',
  MARKETPLACES = 'MARKETPLACES',
  OTHER = 'OTHER'
}

export enum Communication {
  GOOGLE = 'GOOGLE',
  LINKEDIN = 'LINKEDIN',
  WORD_OF_MOUTH = 'WORD_OF_MOUTH',
  EVENT = 'EVENT',
  OTHER = 'OTHER'
}

export interface SaveClientInformationDto {
  companyName: string;
  companyType: Type;
  companyIndustry: Industry;
  companyCommunication?: Communication;
  companyPhone?: string;
}
