import { styled, TextField } from '@mui/material';

export const Dropdown = styled(TextField)`
  & .MuiInput-input {
    &:focus {
      background-color: transparent;
    }
  }

  & .MuiInput-root:before {
    border-bottom: 2px solid ${({ theme }) => theme.palette.text.primary};
  }
  margin-top: 0px;
  margin-bottom: 16px;
`;

export const StyledTextField = styled(TextField)`
  & .MuiInput-root:before {
    border-bottom: 2px solid ${({ theme }) => theme.palette.text.primary};
  }
  margin-top: 0px;
  margin-bottom: 16px;
`;
