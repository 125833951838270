import * as Yup from 'yup';
import { FC, useEffect, useState } from 'react';
import { Formik } from 'formik';

import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  CircularProgress,
  Link,
  MenuItem,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import {
  CheckFirstFormLogo,
  FloatFormContainer
} from 'src/components/StyledForm';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
  Communication,
  Industry,
  SaveClientInformationDto,
  Type
} from 'src/content/dashboards/Clients/types';
import * as S from './styled';

const params = new URLSearchParams(window.location.search);

const RegisterVerification: FC = () => {
  console.log('avav');
  const { userVerification, createUserClient, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const token = params.get('token');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [checked, setChecked] = useState(false);

  const companyTypes = [
    {
      value: `${t('COMPANY')}`.toUpperCase(),
      label: t('COMPANY')
    },
    {
      value: `${t('INSPECTOR')}`.toUpperCase(),
      label: t('INSPECTOR')
    },
    {
      value: `${t('OTHER')}`.toUpperCase(),
      label: t('OTHER')
    }
  ];

  const companyIndustries = [
    {
      value: `${t('INDUSTRIES.TESTING')}`.toUpperCase(),
      label: t('INDUSTRIES.TESTING')
    },
    {
      value: `${t('INDUSTRIES.COMPLIANCE')}`.toUpperCase(),
      label: t('INDUSTRIES.COMPLIANCE')
    },
    {
      value: `${t('INDUSTRIES.AUDIT')}`.toUpperCase(),
      label: t('INDUSTRIES.AUDIT')
    },
    {
      value: `${t('INDUSTRIES.CERTIFICATION')}`.toUpperCase(),
      label: t('INDUSTRIES.CERTIFICATION')
    },
    {
      value: `${t('INDUSTRIES.INSPECTIONS')}`.toUpperCase(),
      label: t('INDUSTRIES.INSPECTIONS')
    },
    {
      value: `${t('INDUSTRIES.CONSTRUCTION')}`.toUpperCase(),
      label: t('INDUSTRIES.CONSTRUCTION')
    },
    {
      value: `${t('INDUSTRIES.MANUFACTURING')}`.toUpperCase(),
      label: t('INDUSTRIES.MANUFACTURING')
    },
    {
      value: `${t('INDUSTRIES.MARKETPLACES')}`.toUpperCase(),
      label: t('INDUSTRIES.MARKETPLACES')
    },
    {
      value: `${t('OTHER')}`.toUpperCase(),
      label: t('OTHER')
    }
  ];

  const companyCommunications = [
    {
      value: `${t('COMMUNICATIONS.GOOGLE')}`.toUpperCase(),
      label: t('COMMUNICATIONS.GOOGLE')
    },
    {
      value: `${t('COMMUNICATIONS.LINKEDIN')}`.toUpperCase(),
      label: t('COMMUNICATIONS.LINKEDIN')
    },
    {
      value: `${t('COMMUNICATIONS.WORD_OF_MOUTH')}`.toUpperCase(),
      label: t('COMMUNICATIONS.WORD_OF_MOUTH')
    },
    {
      value: `${t('COMMUNICATIONS.EVENT')}`.toUpperCase(),
      label: t('COMMUNICATIONS.EVENT')
    },
    {
      value: `${t('OTHER')}`.toUpperCase(),
      label: t('OTHER')
    }
  ];

  // useEffect(() => {
  //   let isMounted = true; // Add a flag to track if the component is mounted
  //   const userVerificationAuth = async () => {
  //     if (!token) {
  //       // If no token, set loading to false and handle the automatic verification
  //       if (isMounted) {
  //         setIsLoading(false);
  //         // Optionally, navigate to a different page or show a message
  //         navigate('/dashboard'); // Redirect to the dashboard or another appropriate page
  //       }
  //       return; // Exit the function early
  //     }

  //     try {
  //       await userVerification(token);
  //     } catch (error) {
  //       logout();
  //       navigate('/login');
  //     }

  //     if (isMounted) {
  //       setIsLoading(false);
  //     }
  //   };

  //   userVerificationAuth();

  //   return () => {
  //     isMounted = false; // Cleanup function to cancel any ongoing tasks when the component is unmounted
  //   };
  // }, [token]);

  useEffect(() => {
    const userVerificationAuth = async () => {
      if (!token) {
        setIsLoading(false);
        return;
      }

      try {
        await userVerification(token);
      } catch (error) {
        logout();
        navigate('/login');
      } finally {
        setIsLoading(false);
      }
    };

    userVerificationAuth();
  }, [token, navigate, logout]);

  if (isLoading) {
    return <CircularProgress />;
  }

  const registerClientCompany = async (company: SaveClientInformationDto) => {
    try {
      await createUserClient(company);
      navigate('/checkfirst');
    } catch (err) {
      console.log(err);
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  if (isLoading) {
    return null; // Return null or a loading spinner while waiting for userVerificationAuth to resolve
  }

  return (
    <>
      <FloatFormContainer maxWidth="xs">
        <Card
          sx={{
            padding: 3,
            borderRadius: theme.spacing(0.75),
            border: 1,
            borderColor: theme.borders.color
          }}
        >
          <Box
            component="img"
            sx={{ width: theme.spacing(10), height: theme.spacing(10) }}
            alt={t('LOGO_ALT')}
            src="/static/images/logo/checkfirst-Icon-logo-dark.png"
          />
          <CardHeader
            title={
              <Typography fontSize={theme.spacing(5)}>
                {t('REGISTRATION_LAST_STEP')}
              </Typography>
            }
            subheader={
              <Typography
                fontSize={theme.spacing(2)}
                color={theme.borders.color}
              >
                {t('KNOW_MORE_INFO')}{' '}
                <Link
                  underline="always"
                  href="https://www.checkfirst.ai/"
                  color={theme.borders.color}
                >
                  {t('CHECKFIRST_AI')}
                </Link>
              </Typography>
            }
            subheaderTypographyProps={{
              color: `${theme.colors.primary.main}80`,
              marginTop: 0.5
            }}
            disableTypography={true}
            sx={{ padding: 0, paddingBottom: theme.spacing(2.5) }}
          />
          <Formik
            initialValues={{
              companyName: '',
              companyType: '',
              companyIndustry: '',
              companyCommunication: null,
              companyPhone: null,
              submit: ''
            }}
            validationSchema={Yup.object().shape({
              companyName: Yup.string()
                .max(255)
                .required(t('COMPANY_VALIDATION')),
              companyType: Yup.mixed<Type>()
                .oneOf(Object.values(Type))
                .required(t('COMPANY_TYPE_VALIDATION')),
              companyIndustry: Yup.mixed<Industry>()
                .oneOf(Object.values(Industry))
                .required(t('COMPANY_INDUSTRY_VALIDATION')),
              companyCommunication: Yup.mixed<Communication>()
                .oneOf([null].concat(Object.values(Communication)))
                .nullable()
                .optional(),
              companyPhone: Yup.string()
                .min(8, t('COMPANY_PHONE_MIN'))
                .max(32, t('COMPANY_PHONE_MAX'))
                .optional()
                .nullable()
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ): Promise<void> => {
              await registerClientCompany({
                companyName: values.companyName.trim(),
                companyType: values.companyType as Type,
                companyIndustry: values.companyIndustry as Industry,
                companyCommunication:
                  values.companyCommunication as Communication,
                companyPhone: values.companyPhone.trim()
              });
              setStatus({ success: true });
              setSubmitting(false);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }): JSX.Element => (
              <form noValidate onSubmit={handleSubmit}>
                <S.Dropdown
                  variant="standard"
                  error={Boolean(touched.companyType && errors.companyType)}
                  fullWidth
                  margin="normal"
                  helperText={touched.companyType && errors.companyType}
                  label={
                    `${t('COMPANY')} ${t('AUTH_SIGN_UP_CARD_OR_SEPARATOR')} ` +
                    `${t('INSPECTOR')}`.toLocaleLowerCase()
                  }
                  name="companyType"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.companyType}
                  select
                  required
                >
                  {companyTypes.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </S.Dropdown>
                <S.Dropdown
                  error={Boolean(
                    touched.companyIndustry && errors.companyIndustry
                  )}
                  fullWidth
                  margin="normal"
                  helperText={touched.companyIndustry && errors.companyIndustry}
                  label={
                    `${t('INDUSTRY')} ` +
                    `${t('ISSUE_DESCRIPTION')}`.toLocaleLowerCase()
                  }
                  name="companyIndustry"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.companyIndustry}
                  select
                  required
                >
                  {companyIndustries.map((industry) => (
                    <MenuItem key={industry.value} value={industry.value}>
                      {industry.label}
                    </MenuItem>
                  ))}
                </S.Dropdown>
                <S.StyledTextField
                  error={Boolean(touched.companyName && errors.companyName)}
                  fullWidth
                  margin="normal"
                  helperText={touched.companyName && errors.companyName}
                  label={t('COMPANY_LABEL')}
                  name="companyName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.companyName}
                  required
                />
                <S.StyledTextField
                  error={Boolean(touched.companyPhone && errors.companyPhone)}
                  fullWidth
                  margin="normal"
                  helperText={touched.companyPhone && errors.companyPhone}
                  label={`${t('PHONE_NUMBER')}`}
                  name="companyPhone"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.companyPhone}
                  onKeyPress={(e) => {
                    if (!/^[ 0-9\.\+\-\(\)]*$/.test(e.key)) e.preventDefault();
                  }}
                />
                <S.Dropdown
                  error={Boolean(
                    touched.companyCommunication && errors.companyCommunication
                  )}
                  fullWidth
                  margin="normal"
                  helperText={
                    touched.companyCommunication && errors.companyCommunication
                  }
                  label={t('HOW_TO_COMMUNICATE')}
                  name="companyCommunication"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.companyCommunication}
                  select
                >
                  {companyCommunications.map((communication) => (
                    <MenuItem
                      key={communication.value}
                      value={communication.value}
                    >
                      {communication.label}
                    </MenuItem>
                  ))}
                </S.Dropdown>
                <Box display="flex" alignItems="flex-start">
                  <Checkbox
                    checked={checked}
                    onChange={handleCheckboxChange}
                    sx={{
                      padding: 0,
                      paddingRight: theme.spacing(2),
                      ':hover': {
                        backgroundColor: 'transparent'
                      }
                    }}
                  />
                  <Typography>{t('CREATE_ACCOUNT_DISCLAIMER')}</Typography>
                </Box>
                <Button
                  sx={{
                    mt: theme.spacing(2.5),
                    mb: theme.spacing(2.5),
                    borderRadius: theme.spacing(0.75)
                  }}
                  color="primary"
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={!checked}
                  type="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                >
                  {t('SIGN_UP_HERE')}
                </Button>
                <Typography>
                  {`${t('BY_SIGNING')}`}{' '}
                  <Link
                    color={theme.formFields.active.slice(0, 7)}
                    fontWeight={600}
                    href="https://www.checkfirst.ai/terms-conditions"
                    underline="always"
                  >
                    {t('TERMS_OF_USE')}
                  </Link>{' '}
                  {`${t('AND')} `}{' '}
                  <Link
                    color={theme.formFields.active.slice(0, 7)}
                    fontWeight={600}
                    href="https://www.checkfirst.ai/terms-conditions"
                    underline="always"
                  >
                    {t('PRIVACY_POLICY')}
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Card>
      </FloatFormContainer>
    </>
  );
};

export default RegisterVerification;
